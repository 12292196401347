import moment from "moment";

/*   //Format picker date in YYY-MM-DD
  dateFormat(date) {
    const dateFormatted = new Date(date);
    return `${dateFormatted.getFullYear()}-${dateFormatted.getMonth() +
      1}-${dateFormatted.getDate()}`;
  },

  //Format picker hours in HH:mm
  timeFormat(time) {
    const hourFormatted = new Date(time);
    return (
      `${hourFormatted.getHours()}:${
        hourFormatted.getMinutes() < 10 ? "0" : ""
      }` + `${hourFormatted.getMinutes()}`
    );
  }, */

/**
 * Check if field is null
 */
export function checkField(field) {
  if (!field || field == "") {
    return "-";
  } else {
    return field;
  }
}

/**
 * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
 */
export function dateFormat(date) {
  if (date && moment.isDate(new Date(date))) {
    return moment(date).format("DD/MM/YYYY");
  } else {
    return "-";
  }
}

/**
 * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY H:i
 */
export function dateFormatWithMinutes(date) {
  if (date && moment.isDate(new Date(date))) {
    return moment(date).format("DD/MM/YYYY, HH:mm");
  } else {
    return "-";
  }
}

/**
 * Format date in HH:mm
 */
export function timeFormat(date) {
  if (date && moment.isDate(new Date(date))) {
    return moment(date).format("HH:mm");
  } else {
    return "-";
  }
}
